<template>
  <div class="dashboard-lab-order-detail">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="my-account-wrap">
          <div class="columns content-my-account-wrap">
            <lab-menu></lab-menu>
            <div class="column is-12 right-column is-empty" v-if="order === null">
              <div class="columns">
                <div class="column is-8">
                  <h3 class="title is-4 pb-4 txt-c-mobile" v-if="isLoading">Searching por el pedido</h3>
                  <h3 class="title is-4 pb-4 txt-c-mobile" v-if="!isLoading">No pedido found</h3>
                </div>
                <div class="column is-4 has-text-right txt-c-mobile">
                  <router-link :to="{ name: 'LaboratoryLabOrders' }" class="is-underlined">Volver a todos los pedidos</router-link>
                </div>
              </div>
            </div>
            <div class="column is-12 right-column" v-if="order !== null">
              <div class="columns">
                <div class="column is-8">
                  <h3 class="title is-4 pb-4 txt-c-mobile">Pedido #{{order.order_number}} - {{order.created_at.split('T')[0]}}</h3>
                </div>
                <div class="column is-4 has-text-right txt-c-mobile">
                  <router-link :to="{ name: 'LaboratoryLabOrders' }" class="is-underlined">Volver a todos los pedidos</router-link>
                </div>
              </div>

              <div class="columns mb-0">
                <div class="column is-6 mb-0">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    Estado
                  </h3>
                </div>
                <div class="column is-6 mb-0 has-text-right">
                  <!-- Created -->
                  <h3 class="order-status red mb-0" v-if="order.status === 'created'">
                    To be approved <span v-if="order.status === 'under_review'">(under review)</span>
                  </h3>
                  <!-- Started -->
                  <h3 class="order-status yellow mb-0" v-if="order.status === 'started'">
                    El pedido se está produciendo
                  </h3>
                  <!-- Processed -->
                  <h3 class="order-status yellow mb-0" v-if="order.status === 'processed'">
                    El pedido está listo para el envío
                  </h3>
                  <button class="icon-button mt-0" v-if="order.status === 'processed'">
                    <span class="icon icon-download"></span> <span class="btn-label">Descarga las etiquetas de envío y las instrucciones</span>
                  </button>
                  <!-- Shipped -->
                  <h3 class="order-status green mb-0" v-if="order.status === 'shipped'">
                    Pedido enviado
                  </h3>
                  <!-- Delivered -->
                  <h3 class="order-status green mb-0" v-if="order.status === 'delivered'">
                    Pedido DELIVERED
                  </h3>
                  <!-- Canceled -->
                  <h3 class="order-status red mb-0" v-if="order.status === 'cancelled'">
                    Pedido CANCELED
                  </h3>
                  <!-- Archived -->
                  <h3 class="order-status mb-0" v-if="order.status === 'archived'">
                    Pedido Archived
                  </h3>
                </div>
              </div>

              <hr>

              <div class="columns mb-0 is-flex-wrap-wrap">
                <div class="column is-3 mb-0">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    Clinica
                  </h3>
                </div>
                <div class="column is-9 mb-0">
                  <p>
                    <span class="has-text-weight-bold">{{order.clinic_name}}</span><br>
                    {{order.shipping_address.city}}, {{order.shipping_address.province}}
                  </p>
                </div>

                <div class="column is-3 mb-0">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    Producto
                  </h3>
                </div>
                <div class="column is-9 mb-0">
                  <p>
                    <span class="has-text-weight-bold">{{orderItem.product_name}}</span><br>
                    CANTITAD {{orderItem.quantity}}
                  </p>
                </div>

                <div class="column is-3 mb-0">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    Paziente
                  </h3>
                </div>
                <div class="column is-9 mb-0">
                  <p>
                    <span class="has-text-weight-bold">{{orderItem.first_name}} {{orderItem.last_name}}</span><br>
                    NUMERO EXPEDIENTE: {{(orderItem.file_number == null) ? 'no numero' : orderItem.file_number}}
                  </p>
                </div>

                <div class="column is-3 mb-0">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    Fecha de entrega deseada
                  </h3>
                </div>
                <div class="column is-9 mb-0">
                  <p>
                    <span class="has-text-weight-bold">XX/XX/XXXX</span><br>
                  </p>
                  <p class="has-text-danger">
                    Last modified: <span class="has-text-weight-bold">X days ago</span> by <span class="has-text-weight-bold">XXXXX</span>
                  </p>
                </div>

                <div class="column is-3 mb-0">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    Precio
                  </h3>
                </div>
                <div class="column is-9 mb-0">
                  <p>
                    <span class="has-text-weight-bold">{{orderItem.total_price / 100}} €</span>
                  </p>
                </div>
              </div>

              <div class="columns mb-3">
                <div class="column is-12">
                  <complementary-list
                    v-if="extras.length > 0"
                    :product-offer-id="orderItem.product_offer_id"
                    :active-extras="extras"
                    :update-cb="fetchOrderDetail"
                    :show-edit-extra="true"></complementary-list>
                </div>
              </div>

              <div class="columns mb-3">
                <div class="column is-4">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase mb-3">
                    DETALLES DE LA ORDEN
                  </h3>
                  <button class="icon-button" @click="openOrderDetail(orderItem)">
                    <span class="icon icon-view-eye"></span> <span class="btn-label">Ver detalles</span>
                  </button>
                </div>
                <div class="column is-4">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase mb-3">
                    ARCHIVIO
                  </h3>
                  <p v-if="orderItem.uploads.length === 0">No file</p>
                  <button v-if="orderItem.uploads.length > 0" class="icon-button" @click="downloadFile(orderItem.uploads)">
                    <span class="icon icon-download"></span> <span class="btn-label">Descarga todo</span>
                  </button>
                </div>
                <div class="column is-4 has-text-right">
                  <button class="button is-danger is-outlined mt-5" :disabled="order.status !== 'created'" v-if="orderItem.status !== 'under_review'" @click="askRevision(orderItem)">Pedir Revisión</button>
                  <button :disabled="true" class="button is-primary is-outlined mt-5" v-if="orderItem.status === 'under_review'">Revisión pedida</button>
                </div>
              </div>

              <hr>

              <div class="columns mb-3 communication-center is-flex-wrap-wrap">
                <div class="column is-12">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    communication center
                  </h3>
                </div>
                <div class="column is-12">
                  <communication-center v-if="!isLoading &&  order.status !== 'archived' && order.status !== 'cancelled'" :order-id="order.id" :name="laboratory.name"></communication-center>
                </div>
              </div>

              <hr>

              <div class="columns mb-3" v-if="order.status === 'created'">
                <div class="column is-7">
                  <p>
                    NOTA - Aprobar este pedido cambiará su estado a "EN PROCESO". El paso siguiente es la fabricación de la pieza por parte del laboratorio
                  </p>
                  <p>
                    <a class="is-underlined" href="#">Contactar con nosotros</a><br>
                    <a class="is-underlined" href="#">Ver la sección de ayuda</a><br>
                    <a class="is-underlined" href="#">Ver la sección de ayuda</a>
                  </p>
                </div>
                <div class="column is-5">
                  <button class="button is-primary is-fullwidth is-medium" @click="setOrderToStarted()">Aprobar el pedido</button>
                  <p class="has-text-centered mt-4">
                    <a class="is-underlined" href="#" @click="setOrderToCancel()">Rechazar el pedido</a>
                  </p>
                </div>
              </div>

              <hr>

              <div class="columns mb-3 is-flex-wrap-wrap">
                <div class="column is-12">
                  <file-upload-stlab :title-uploader="'Attachments (e.g. Certificates, Documents...)'" :type="'pdf'" :file-supported="'pdf'" :destination-id="orderItem.id" :is-laboratory="true" :uploaded-files="orderItem.uploads"></file-upload-stlab>
                </div>
              </div>

              <hr>

              <div class="columns is-variable is-3 is-flex-wrap-wrap mb-3">
                <div class="column is-6 mb-0">
                  <div class="has-background-light location-box">
                    <p class="pb-2"><strong class="txt-yellow is-uppercase">Dirección de envío</strong></p>
                    <p>
                      <strong>{{order.shipping_address.name}}</strong><br>
                      {{order.shipping_address.address1}}<br>
                      {{order.shipping_address.zip}} {{order.shipping_address.city}} ({{order.shipping_address.province}}), {{order.shipping_address.country}}<br>
                      {{order.shipping_address.phone}}<br>
                    </p>
                  </div>
                </div>
                <div class="column is-6 mb-0">
                  <div class="has-background-light location-box">
                    <p class="pb-2"><strong class="txt-yellow is-uppercase">Dirección de facturación</strong></p>
                    <p>
                      <strong>{{order.billing_address.name}}</strong><br>
                      {{order.billing_address.address1}}<br>
                      {{order.billing_address.zip}} {{order.billing_address.city}} ({{order.billing_address.province}}), {{order.billing_address.country}}<br>
                      {{order.billing_address.phone}}<br>
                    </p>
                  </div>
                </div>
              </div>

              <div class="columns mb-3">
                <div class="column is-12 mb-0">
                  <div class="has-background-light total-box is-flex is-flex-direction-row is-justify-content-space-between" style="display: flex;">
                    <h3 class="has-text-weight-bold txt-yellow is-uppercase" style="display: inline-block;">
                      total
                    </h3>
                    <span class="has-text-weight-bold" style="display: inline-block;">{{order.total_price / 100}} €</span>
                  </div>
                </div>
              </div>

              <div class="columns" v-if="order.status !== 'under_review' && order.status !== 'created' && order.status !== 'cancelled' && order.status !== 'archived'">
                <div class="column is-12 mb-0">
                  <h3 class="title has-text-weight-bold mb-4 is-5 txt-c-mobile">
                    ¿Qué hacer cuando el pedido esté listo?
                  </h3>
                  <p v-if="order.status === 'started'">
                    <strong>PASO 1</strong> - Confirmar la "PRODUCCIÓN TERMINADA"<br>
                    <strong>PASO 2</strong> - Seguir las instrucciones de envío a continuación
                  </p>
                  <p v-if="order.status === 'processed'">
                    <strong>PASO 1</strong> - Confirmar "SHIP ORDER"<br>
                    <strong>PASO 2</strong> - Seguir las instrucciones de envío a continuación
                  </p>
                  <p v-if="order.status === 'shipped'">
                    <strong>PASO 1</strong> - Confirmar "ORDER DELIVERED"<br>
                    <strong>PASO 2</strong> - Seguir las instrucciones de envío a continuación
                  </p>
                  <!-- <button class="icon-button mt-3">
                    <span class="icon icon-download"></span> <span class="btn-label">Descarga las etiquetas de envío y las instrucciones</span>
                  </button> -->
                  <button v-if="order.status === 'started'" class="button is-primary is-fullwidth is-medium mt-5" @click="setOrderToProcessed()">Producción terminada</button>

                  <button v-if="order.status === 'processed'" class="button is-primary is-fullwidth is-medium mt-5" @click="setOrderToShipped()">SHIP ORDER</button>

                  <!-- <button v-if="order.status === 'shipped'" class="button is-primary is-fullwidth is-medium mt-5" @click="setOrderToDelivered()">ORDER DELIVERED</button> -->

                  <button v-if="order.status === 'delivered'" class="button is-primary is-fullwidth is-medium mt-5" @click="setOrderToArchive()">ARCHIVE ORDER</button>
                  <p class="has-text-centered mt-4" v-if="order.status !== 'shipped' && order.status !== 'delivered' ">
                    <a class="is-underlined" href="#" @click="setOrderToCancel()">Rechazar el pedido</a>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import FileUploadStlab from '@/components/FileUploadStlab.vue'
import LabMenu from '@/components/Laboratory/LabMenu.vue'
import ComplementaryList from '@/components/ComplementaryList.vue'
import CommunicationCenter from '@/components/CommunicationCenter.vue'
import {
  HumanFileSize,
  ScrollTo
} from '@/utils/utils'
import { mapGetters } from 'vuex'
export default {
  name: 'LaboratoryOrderDetail',
  components: {
    HeroImage,
    Footer,
    LabMenu,
    ComplementaryList,
    CommunicationCenter,
    FileUploadStlab
  },
  data () {
    return {
      isLoading: true,
      order: null,
      extras: [],
      orderItem: null
    }
  },
  computed: {
    ...mapGetters({
      laboratory: 'user/laboratory'
    })
  },
  methods: {
    // UPLOAD
    convertSize (size) {
      return HumanFileSize(size)
    },
    downloadFile (uploads) {
      if (uploads.length > 0) {
        const self = this
        for (let i = 0; i < uploads.length; i++) {
          this.$store.dispatch('clinic/downloadFile', {
            fileId: uploads[i].id
          }).then(response => {
            const blobby = response.data
            // var blobObj = new Blob([data], { type: response.headers['content-type'] })
            const url = window.URL.createObjectURL(blobby)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', response.headers['content-disposition'].split('"')[1])
            document.body.appendChild(link)
            link.click()
            link.remove()

            window.URL.revokeObjectURL(url)
          })
            .catch(function (error) {
              self.$store.commit({
                type: 'application/ADD_FEEDBACK',
                feedback: {
                  type: 'error',
                  message: error
                }
              })
            })
        }
      }
    },
    setOrderToStarted () {
      const self = this
      this.$store.dispatch('lab/startOrder', {
        orderId: this.order.id,
        comment: '',
        cb: (response) => {
          console.log(response)
          self.order = response
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Order status chenged correctly'
            }
          })
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on changing the order status'
            }
          })
        }
      })
    },
    setOrderToProcessed () {
      const self = this
      this.$store.dispatch('lab/processedOrder', {
        orderId: this.order.id,
        comment: '',
        cb: (response) => {
          console.log(response)
          self.order = response
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Order status chenged correctly'
            }
          })
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on changing the order status'
            }
          })
        }
      })
    },
    setOrderToShipped () {
      const self = this
      this.$store.dispatch('lab/shippedOrder', {
        orderId: this.order.id,
        comment: '',
        cb: (response) => {
          console.log(response)
          self.order = response
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Order status chenged correctly'
            }
          })
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on changing the order status'
            }
          })
        }
      })
    },
    setOrderToDelivered () {
      const self = this
      this.$store.dispatch('lab/deliveredOrder', {
        orderId: this.order.id,
        comment: '',
        cb: (response) => {
          console.log(response)
          self.order = response
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Order status chenged correctly'
            }
          })
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on changing the order status'
            }
          })
        }
      })
    },
    setOrderToCancel () {
      const self = this
      this.$store.dispatch('lab/cancelledOrder', {
        orderId: this.order.id,
        comment: '',
        cb: (response) => {
          console.log(response)
          self.order = response
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Order status chenged correctly'
            }
          })
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on changing the order status'
            }
          })
        }
      })
    },
    setOrderToArchive () {
      const self = this
      this.$store.dispatch('lab/archivedOrder', {
        orderId: this.order.id,
        comment: '',
        cb: (response) => {
          console.log(response)
          self.order = response
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Order status chenged correctly'
            }
          })
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on changing the order status'
            }
          })
        }
      })
    },
    logout () {
      const self = this
      this.$store.dispatch('user/logOut', {
        cb: () => {
          self.goTo('/')
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on the log out'
            }
          })
        }
      })
    },
    openOrderDetail (orderItem) {
      const modalOptions = {
        componentId: 'CompleteOrderDetails',
        title: 'Pedido review',
        laboratory: this.laboratory,
        productId: orderItem.product_id,
        productName: orderItem.product_name,
        productOfferId: orderItem.product_offer_id,
        productOfferPrice: orderItem.unit_price,
        patientName: orderItem.first_name,
        patientLastName: orderItem.last_name,
        odontologistName: orderItem.odontologist_name,
        shoppingCartItem: orderItem,
        activeExtras: this.extras,
        orderId: this.order.id,
        smallSize: false,
        isUpdate: false,
        isOrder: true,
        isReview: false,
        readOnly: true,
        updateCb: null,
        closeCb: this.fetchOrderDetail
      }
      this.$store.commit({
        type: 'application/SHOW_MODAL',
        modalOptions: modalOptions
      })
    },
    askRevision (orderItem) {
      const modalOptions = {
        componentId: 'OrderRevision',
        title: 'Revisar la orden del pedido #' + this.order.order_number,
        orderItem: orderItem,
        orderId: this.order.id,
        smallSize: false
      }
      this.$store.commit({
        type: 'application/SHOW_MODAL',
        modalOptions: modalOptions
      })
    },
    goTo (path) {
      this.$router.push({ path: path })
    },
    initOrderItem () {
      let i = 0
      for (i = 0; i < this.order.order_items.length; i++) {
        if (!this.order.order_items[i].complementary) {
          this.orderItem = this.order.order_items[i]
          break
        }
      }
    },
    fetchOrderDetail () {
      const self = this
      this.isLoading = true
      this.extras = []
      ScrollTo(document.documentElement, 0, 500)
      this.$store.dispatch('lab/getLabOrderById', {
        orderId: this.$route.params.orderId,
        cb: (response) => {
          for (let i = 0; i < response.order_items.length; i++) {
            response.order_items[i].dental_impression_id = null
            // set extras
            if (response.order_items[i].extra) {
              self.extras.push(response.order_items[i])
            }
          }

          self.order = response
          self.initOrderItem()
          self.isLoading = false
        },
        cbError: (error) => {
          self.isLoading = false
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    }
    /* hasFile () {
      for (let i = 0; i < this.modalOptions.shoppingCartItem.uploads.length; i++) {
        switch (this.modalOptions.shoppingCartItem.uploads[i].upload_type) {
          case 'stl':
            this.uploadedStlFiles.push(this.modalOptions.shoppingCartItem.uploads[i])
            break
          case 'image':
            this.uploadedImgFiles.push(this.modalOptions.shoppingCartItem.uploads[i])
            break
          case 'dicom':
            this.uploadedDcomFiles.push(this.modalOptions.shoppingCartItem.uploads[i])
            break
          default:
        }
      }
      for (let i = 0; i < this.order.order_items.length; i++) {
        for (let j = 0; j < this.order.order_items[i].order_item_fields.length; j++) {
          if (!IsEmptyOrNull(this.order.order_items[i].order_item_fields[j].dental_impression_id)) {
            // File attachment found
            this.order.order_items[i].dental_impression_id = this.order.order_items[i].order_item_fields[j].dental_impression_id
          }
        }
      }
      console.log(this.order)
    } */
  },
  created () {
    this.fetchOrderDetail()
  }
}
</script>

<style scoped lang="scss">
.dashboard-lab-order-detail{
  width: 100%;
  section{
    width: 100%;
    padding: 0px;
    &.over-hero{
      position: relative;
      margin-top: -430px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .my-account-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-my-account-wrap{
        flex-direction: column;
        padding: 0px;
        .has-background-light{
          border-radius: 4px 0px 0px 4px;
        }
        .right-column{
          padding: 80px;
          .icon-button{
            position: relative;
            background-color: transparent;
            border: 0px;
            cursor: pointer;
            [class^="icon"]{
              font-weight: bold;
              font-size: 1.3rem;
              position: relative;
              top: 5px;
            }
            .btn-label{
              font-size: 1rem;
              font-family: Avenir, Helvetica, Arial, sans-serif;
              font-family: "basic-sans",sans-serif;
              text-decoration: underline;
            }
          }
          .order-status{
            position: relative;
            padding-right: 40px;
            margin-bottom: 21px;
            &:before{
              position: absolute;
              right: 0px;
              top: -2px;
              content: "";
              height: 23px;
              width: 23px;
              border-radius: 50%;
              background-color: #57567C;
            }
            &.red{
              &:before{
                background-color: #E37878;
              }
            }
            &.yellow{
              &:before{
                background-color: #FFD514;
              }
            }
            &.green{
              &:before{
                background-color: #94C38E;
              }
            }
          }

          .location-box, .total-box{
            border-radius: 8px;
            padding: 21px 28px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboard-lab-order-detail{
    section{
      .my-account-wrap{
        .content-my-account-wrap{
          .right-column{
            .product-wrap{
              padding: 24px;
              width: 100%;
              border-radius: 8px;
              background-color: #FFFFFF;
              box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
              border: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
